import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { SplitTreatments, useTreatments } from '@splitsoftware/splitio-react';
import { Button, Alert } from '@hagerty/react-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { isAgent } from 'src/api/client';
import useAddOrder from 'src/api/useAddOrder';
import useDefaultPaymentMethod from 'src/api/useDefaultPaymentMethod';
import useMembership from 'src/api/useMembership';
import useActiveProduct from 'src/api/useActiveProduct';
import useProfile from 'src/api/useProfile';
import usePreventMemberCheckout from 'src/hooks/usePreventMemberCheckout';
import { useUserTracker } from 'src/hooks/useUserTracker';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import { trackPageLoad } from 'src/services/tracker';
import { getPurchaseFlowRedirect } from 'src/helpers/purchaseFlowRedirect';
import isBrowser from 'src/helpers//isBrowser';
import { missingInvalidErrorCodes } from 'src/constants/stripe';

import { useAuthContext } from 'src/components/AuthProvider';
import { login } from 'src/components/AuthProvider/browser';
import { ChangePlanWidget, FreeTrialWidget, PaymentDisclaimer } from 'src/components/molecule';
import { PaymentMethod } from 'src/components/organism';
import { CheckoutFormStripe } from 'src/components/CheckoutFormStripe';
import { Translate } from 'src/components/Translate';
import LoadingContainer from 'src/components/LoadingContainer';
import CheckoutFormMP from 'src/components/CheckoutFormMP';
import ShelbyFooter from 'src/components/organism/ShelbyFooter/ShelbyFooter';
import { isReady } from 'src/components/SplitContext/isReady';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { useTracking } from 'src/components/TrackingContext';
import { Disclaimer } from 'src/components/Disclaimers';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { usePurchaseFlowOptimization } from 'src/hooks/usePurchaseFlowOptimization';
import { useByPartnerCustomerIdMembership } from 'src/api/useByPartnerCustomerIdMembership';
import { HdcMembershipStatus } from 'src/constants/HdcMembershipStatus';
import { useMembershipLevel } from 'src/hooks/use-membership-level';

import { PaymentProvider } from 'src/constants/Payment';
import { Split, Treatment } from '../../../constants/Split';
import { formatTestExperience } from 'src/helpers/formatTestExperience';
import scrollToTop from 'src/helpers/scrollToTop';
import { SimpleFooter } from 'src/features/footer';
import { CUSTOMER_EMAIL_SKIPPED } from 'src/constants/email';

import styles from '../index.module.scss';
import { hdcLevels } from 'src/components/CommissionsDataTableContainer/CommissionsDataTable.types';

const metadata = {
  title: 'Checkout Payment Information | Hagerty Drivers Club',
  sfTitle: 'Checkout Payment Information | HDC for State Farm',
  description: 'Enter your payment method to purchase Hagerty Drivers Club membership..',
};

const i18n = {
  headline: 'checkout.paymentPage.headline',
  paymentDesc: 'checkout.paymentPage.paymentDesc',
  buttonText: 'checkout.paymentPage.buttonText',
  existingCardText: 'checkout.paymentPage.existingCard.text',
  freeTrialCardText: 'checkout.paymentPage.freeTrialCard.text',
  agentCardText: 'checkout.paymentPage.agentCard.text',
  alertError: 'checkout.payment.error',
  alertMissingTitle: 'checkout.payment.error.missing-invalid.title',
  alertTitle: 'checkout.payment.error.title',
  alertSuccess: 'checkout.payment.success.banner',
  genericMsg: 'generic.error.message',
  unsupportedPaymentProviderMsg: 'checkout.paymentPage.unsupportedPaymentProviderMsg',
  form: {
    checkboxLabel: 'checkout.form.checkbox.label',
    privacyPolicy: 'checkout.form.checkbox.privacyPolicy',
    roadsideService: 'checkout.form.checkbox.roadsideService',
    tos: 'checkout.form.checkbox.tos',
  },
  breadcrumb: 'checkout.paymentPage.back',
};

type Cart = {
  id: string;
  membershipId: string;
  productId: string;
  paymentType: string;
};

const defaultCartData = {
  membershipId: '',
  productId: '',
  id: '',
  paymentType: '',
};

function PaymentPage({ location }) {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    unregister,
    reset,
    formState: { isSubmitting, isSubmitted, isValid },
    ...useFormFields
  } = useForm({ mode: 'onChange' });
  const { t } = useTranslation();
  const [paymentIntent, setPaymentIntent] = useState<any>();
  const [confirmation, setConfirmation] = useState({});
  const [showForm, setShowForm] = useState<boolean>();
  const [displayAlert, setDisplayAlert] = useState<boolean>(true);
  const [alertTitle, setAlertTitle] = useState<string>(t(i18n.alertTitle));
  const [alertMessage, setAlertMessage] = useState<string>(t(i18n.alertError));
  const [submitFormError, setSubmitFormError] = useState<string>();
  const [cartData, setCartData] = useState<Cart>(defaultCartData);
  const [formData, setFormData] = useState({});
  const [moneyPlatformData, setMoneyPlatformData] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [handleFreeTrial, setHandleFreeTrial] = useState(false);
  const [isHandlingFreeTrial, setIsHandlingFreeTrial] = useState(false);
  const { isAuthenticated, loading } = useAuthContext();
  const { data: profile, loading: profileLoading } = useProfile({ fetchPolicy: 'network-only' });
  const [pageIsLoading, setIsPageLoading] = useState(true);

  // fallback to user Id / omni Id
  const hagertyAccountId = profile?.ghaid ? profile?.ghaid : profile?.id;

  const skipExistMemberRedirect = !isAgent && usePreventMemberCheckout();
  const { isStfrmCustomer, hasStfCustomerStandalonePurchase } = useShelbyCustomer();
  const user = useUserTracker();

  const splitIsReady = isReady();
  const { hadFreeTrialProduct, isEligibleForFreeTrial, freeTrialIsReady } = useFreeTrial();
  const treatments = useTreatments([
    Split.MoneyPlatformIintegration,
    Split.FeatureFreeTrial,
    Split.FeatureCustomerPaymentDisclaimer,
    Split.FeaturePurchaseFlowOptimization,
    Split.FeatureCreateAccountCheckoutFlow,
  ]);
  const isMoneyPlatform = treatments[Split.MoneyPlatformIintegration].treatment === Treatment.On;
  const { defaultPaymentMethod, defaultPaymentMethodLoading } = useDefaultPaymentMethod();
  const hasCustomerPaymentDisclaimer = treatments[Split.FeatureCustomerPaymentDisclaimer].treatment === Treatment.On;
  const hasPurchaseFlowOptimization = treatments[Split.FeaturePurchaseFlowOptimization].treatment === Treatment.On;
  const hasCreateAccountCheckoutFlow = treatments[Split.FeatureCreateAccountCheckoutFlow].treatment === Treatment.On;
  // New purchase flow
  const { hasNewPurchaseFlow } = usePurchaseFlowOptimization();

  const { data: byParnterCustomerIdData, loading: byPartnerCustomerIdDataLoading } = useByPartnerCustomerIdMembership();

  const getCheckoutPaymentMethod = () => {
    if (defaultPaymentMethodLoading || profileLoading) return 'LOADING';
    else if (isAgent || isMoneyPlatform) return PaymentProvider.MoneyPlatform;
    else if (defaultPaymentMethod?.provider) return defaultPaymentMethod?.provider;
    else return PaymentProvider.Stripe;
  };

  // STRIPE (default) / LOADING / MONEYPLATFORM
  const paymentProvider = getCheckoutPaymentMethod();

  const addOrder = useAddOrder({
    order: cartData,
    setPaymentIntent,
    paymentProvider,
    confirmation,
    setSubmitFormError,
    setError,
    moneyPlatformData,
    omniId: location.state?.omniId,
  });

  const postPurchaseNavigation = () => {
    const params = new URLSearchParams(location.search);

    if (isAgent) return navigate('/membership-issued/');

    const redirectParam = params.get('redirect');

    if (membership && redirectParam && isBrowser) {
      params.delete('redirect');
      const remainingParams = params.toString() ? `?${params.toString()}` : '';
      /**
       * Use `location` to properly handle redirection to pages outside of this hdc project (hdc-web)
       * Gatsby `navigate` will direct to the hagerty.com/hdc-days root (not hagerty.com/drivers-club/hdc-days)
       * if page does not exist in this project
       */
      return (window.location.href = `${process.env.HDC_HAGERTY_DRIVERS_CLUB_ROOT_URL}/${redirectParam}${remainingParams}`);
    }

    if (params.get('partner_filter')) return navigate(`/partner-offers${location.search}`);

    const urlParams = hasPurchaseFlowOptimization ? '?show_modal=true' : '';
    navigate(membership ? `/benefits/${urlParams}` : '/my-account/', {
      state: { msg: i18n.alertSuccess },
    });
  };

  const level = useMembershipLevel();
  const { product } = useActiveProduct();

  const { data: membership, secondMembershipQueryForLog, ...membershipQuery } = useMembership();
  const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);
  useTracking('checkout_payment_view', [freeTrialExperiment], true);

  useEffect(() => {
    // Navigate to /agent-unauthorized when already a member or MEMBER_NOT_FOUND
    const navigateUnauthorized = () => navigate('/agent-unauthorized');

    if (
      byParnterCustomerIdData?.errors?.length &&
      byParnterCustomerIdData?.errors[0]?.__typename === HdcMembershipStatus.MEMBER_NOT_FOUND
    ) {
      navigateUnauthorized();
    } else if (
      byParnterCustomerIdData?.assets?.length &&
      byParnterCustomerIdData?.assets[0].status === HdcMembershipStatus.ACTIVE
    ) {
      navigateUnauthorized();
    }
  }, [byParnterCustomerIdData]);

  useEffect(() => {
    setIsPageLoading((isAgent && byPartnerCustomerIdDataLoading) || (!isAgent && !freeTrialIsReady));
  }, [byPartnerCustomerIdDataLoading, freeTrialIsReady]);

  useEffect(() => {
    // Send user to Identity Create Account page
    if (!loading && !isAuthenticated && splitIsReady && hasCreateAccountCheckoutFlow && !hasNewPurchaseFlow) {
      login({ acr_values: 'register' });
    }
    // Authenticate user without feature_purchase_flow_optimization flag
    else if (!loading && !isAuthenticated && splitIsReady && !hasNewPurchaseFlow) {
      login();
    }
  }, [loading, isAuthenticated, splitIsReady, hasCreateAccountCheckoutFlow, hasNewPurchaseFlow]);

  // Pageload view
  useEffect(() => {
    if (user && !hasLoaded && !profileLoading && splitIsReady) {
      trackPageLoad({
        _tag_name: 'checkout_payment',
        ...user,
        previous_hdc_free_trial: !!hadFreeTrialProduct,
        product_code: level === 4 ? hdcLevels.FREE_TRIAL : hdcLevels.GEAR_2,
        test_experience: [freeTrialExperiment],
        virtual_page_name: 'payment-information',
      });
      setHasLoaded(true);
    }
  }, [user, hasLoaded, profileLoading, splitIsReady]);

  useEffect(() => {
    unregister(['cardNumber', 'cardExpiry', 'cardCvc']);
  }, []);

  // #hideFormForNewValidPM
  useEffect(() => {
    defaultPaymentMethod?.card ? setShowForm(false) : setShowForm(true);
  }, [defaultPaymentMethod]);

  // #trigger errors refresh when switching "Edit payment method" form
  useEffect(() => {
    reset();
    setSubmitFormError(null);
    setDisplayAlert(false);
    setAlertMessage(t(i18n.alertError));
  }, [showForm]);

  useEffect(() => {
    if (isSubmitting) return;
    if (submitFormError || errors.cardNumber || errors.cardExpiry || errors.cardCvc) {
      if (missingInvalidErrorCodes.includes(submitFormError)) {
        setAlertTitle(t(i18n.alertMissingTitle));
        setAlertMessage(t(i18n.alertError));
      } else {
        setAlertTitle(t(i18n.alertTitle));
        setAlertMessage(t(i18n.alertError));
      }
      setDisplayAlert(true);
      scrollToTop();
    }
  }, [submitFormError, errors]);

  useEffect(() => {
    if (isStfrmCustomer && !hasStfCustomerStandalonePurchase) {
      setDisplayAlert(true);
      setAlertMessage(t('checkout.banner.shelbyCustomer'));
      setAlertTitle('');
      scrollToTop();
    }
  }, [isStfrmCustomer, hasStfCustomerStandalonePurchase]);

  const mileages = product?.mileages;

  // #Wait and navigate to /my-account
  useEffect(() => {
    if (mileages?.length > 0) {
      setCartData((prev) => ({
        ...prev,
        membershipId: membership?.id,
        productId: product?.id,
        ...mileages[level - 1],
      }));
    }

    // After placing the order and the membership matches with the new one created
    if (isSubmitted && membership?.inventory?.id && membership.inventory.id === cartData?.id) {
      membershipQuery.stopPolling();
      // For membership log
      secondMembershipQueryForLog?.stopPolling();

      // Use isEligibleForFreeTrial to check if customer has previously had free trial as well
      if (isEligibleForFreeTrial && isBrowser && window.localStorage) {
        localStorage.setItem('hadFreeTrialProduct', 'true');
      }

      const redirectUrl = getPurchaseFlowRedirect();
      if (redirectUrl) {
        const origin = window.location.hostname === 'localhost' ? window.location.origin : process.env.HAGERTY_ROOT_URL;
        window.location.href = `${origin}${redirectUrl}`;
      } else {
        postPurchaseNavigation();
      }
    }
  }, [membership, membership?.level, product, level]);

  /**
   * Navigate Agent back to /checkout/information if the customer email has not
   * been saved in Omni. This is to prevent submitting payment with Money Platform
   * and then calling POST /orders/create on this payment page and failing with a
   * 400 error due to not having a customer email in Omni.
   */
  useEffect(() => {
    if (isAgent && !profileLoading && profile && !profile.email) {
      const params = new URLSearchParams(location.search);
      const hasCustomerEmailParam = params.get(CUSTOMER_EMAIL_SKIPPED);
      navigate(
        `/checkout/information/${location.search}${!hasCustomerEmailParam ? `&${CUSTOMER_EMAIL_SKIPPED}=1` : ''}`
      );
    }
  }, [isAgent, profile, profileLoading]);

  const onSubmit = async (data) => {
    skipExistMemberRedirect(true);
    setSubmitFormError(null);
    setFormData(data);

    if (isEligibleForFreeTrial && !defaultPaymentMethod?.card) {
      if (isHandlingFreeTrial) setIsHandlingFreeTrial(false);
      setHandleFreeTrial(true);
      setIsHandlingFreeTrial(true);
    } else {
      setPaymentIntent(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>{isAgent ? metadata.sfTitle : metadata.title}</title>
        {!isAgent && <meta name="description" content={metadata.description} />}
      </Helmet>
      <div className="container container_center">
        {!pageIsLoading ? (
          <>
            <>
              {!isEligibleForFreeTrial && (
                <div className="status-info__min-height">
                  <ChangePlanWidget changeUrl={'/checkout/'} />
                </div>
              )}
            </>
            <div className={isEligibleForFreeTrial && styles.freeTrialPayment}>
              <div
                className={
                  isEligibleForFreeTrial ? `status-info ${styles.freeTrialForm}` : `status-info ${styles.paymentForm}`
                }
              >
                {isEligibleForFreeTrial && (
                  <Breadcrumbs
                    originTransKey={i18n.breadcrumb}
                    originPath={'/checkout/information/'}
                    className={`${styles.breadcrumbs}`}
                  />
                )}
                <h2 className="text-display_2">
                  <Translate resourceKey={i18n.headline} />
                </h2>
                {displayAlert && (
                  <>
                    <div className="inset-m"></div>
                    <Alert alertType="danger" messageText={alertMessage} headingText={alertTitle} />
                  </>
                )}
                {isEligibleForFreeTrial && (
                  <div className="display-laptop-none">
                    <FreeTrialWidget />
                  </div>
                )}
                <p className="color-dark-2 wrap">
                  {isAgent ? (
                    <Translate resourceKey={i18n.agentCardText} useHtml />
                  ) : (
                    <Translate
                      resourceKey={isEligibleForFreeTrial ? i18n.freeTrialCardText : i18n.existingCardText}
                      useHtml
                    />
                  )}
                </p>
                {!defaultPaymentMethodLoading && (!isStfrmCustomer || hasStfCustomerStandalonePurchase) && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-wrap gutters_direct">
                      <div className="col xs-size_1-of-1 max-width_content">
                        {defaultPaymentMethod?.card && !showForm ? (
                          <SplitTreatments names={[Split.MoneyPlatformIintegration]}>
                            {({ treatments }) => {
                              const treatment = treatments[Split.MoneyPlatformIintegration].treatment;
                              if (treatment === Treatment.On || paymentProvider === PaymentProvider.MoneyPlatform) {
                                return null;
                              }
                              return (
                                <PaymentMethod
                                  card={defaultPaymentMethod.card}
                                  provider={defaultPaymentMethod.provider}
                                  onEdit={() => setShowForm(true)}
                                  error={(errors.cardNumber || errors.cardExpiry || errors.cardCvc)?.message}
                                />
                              );
                            }}
                          </SplitTreatments>
                        ) : (
                          <>
                            {!isEligibleForFreeTrial && (
                              <legend className="text-heading_4">
                                <Translate resourceKey={i18n.paymentDesc} />
                              </legend>
                            )}
                          </>
                        )}
                        {paymentProvider === PaymentProvider.MoneyPlatform && (
                          <CheckoutFormMP
                            hagertyAccountId={hagertyAccountId}
                            addOrder={addOrder}
                            cartData={cartData}
                            setMoneyPlatformData={setMoneyPlatformData}
                          />
                        )}
                        {paymentProvider === PaymentProvider.Stripe && (
                          // DO NOT hide CheckoutFormStripe with !showForm, it's also responsible for other Stripe logic (like setConfirmation)
                          <CheckoutFormStripe
                            paymentIntent={paymentIntent}
                            setConfirmation={setConfirmation}
                            hideForm={!showForm}
                            defaultCard={defaultPaymentMethod || {}}
                            formData={formData}
                            addOrder={addOrder}
                            useFormFields={{ errors, register, setError, unregister, ...useFormFields }}
                            submitPayment
                            handleFreeTrial={handleFreeTrial}
                            isSubmitting={isHandlingFreeTrial}
                          />
                        )}
                        {hasCustomerPaymentDisclaimer && !isAgent && (
                          <PaymentDisclaimer type="terms" productLevel={level} />
                        )}
                      </div>
                      {(isSubmitting || isSubmitted) && !submitFormError && <LoadingContainer height={40} />}
                    </div>
                    <div className="inset-s" />
                    {paymentProvider === PaymentProvider.Stripe && (
                      <Button
                        name="processCheckout"
                        disabled={!cartData.id || isSubmitting || !isValid || Boolean(paymentIntent)}
                        buttonType="Primary"
                        buttonSize="Large"
                        type="submit"
                        testId="submit-payment-form"
                      >
                        <Translate resourceKey={i18n.buttonText} />
                      </Button>
                    )}
                    {isAgent && <Disclaimer resource={'state-farm-disclaimer.payment'} />}
                    {submitFormError && (
                      <fieldset className="form-element__control has-error" data-cy="form-error">
                        <div className="form-element__help with-spacing">
                          <Translate resourceKey={submitFormError} />
                        </div>
                      </fieldset>
                    )}
                  </form>
                )}
                {defaultPaymentMethodLoading && <LoadingContainer height={300} />}
              </div>
              {isEligibleForFreeTrial && (
                <div className="display-mobile-none">
                  <FreeTrialWidget />
                </div>
              )}
            </div>
          </>
        ) : (
          <LoadingContainer height={300} />
        )}

        {isAgent ? (
          <footer>
            <div className="shelby-separator">
              <ShelbyFooter />
            </div>
          </footer>
        ) : (
          <>
            <div className="inset-l" />

            <SimpleFooter />
          </>
        )}
      </div>
    </>
  );
}

export default PaymentPage;
