const redirectUrlKey = 'purchase_flow_redirect_url';

// Save the redirect_url and redirect to that URL when purchase complete.
const editPurchaseFlowRedirect = (search) => {
  const urlParams = new URLSearchParams(search);
  const param = urlParams.get('redirect_url');
  const redirectUrl = param?.match(/^\//) ? param : '';
  window.localStorage.setItem(redirectUrlKey, redirectUrl);
};

const getPurchaseFlowRedirect = () => window.localStorage.getItem(redirectUrlKey);

export { editPurchaseFlowRedirect, getPurchaseFlowRedirect };
